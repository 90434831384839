import { ErrorMessage, Field } from "formik";
import React, { Component } from "react";
import classNames from "../../helpers/classNameMerger";
import { TextFieldProps } from "../../interfaces/TextFieldProps";

class TextField extends Component<TextFieldProps> {
  render() {
    return (
      <div>
        <label
          htmlFor={this.props.name}
          className={
            this.props.showLabel
              ? "block text-sm font-medium text-gray-700 mb-2"
              : "sr-only"
          }
        >
          {this.props.label}
        </label>
        <Field
          id={this.props.name}
          name={this.props.name}
          type={this.props.type}
          className={classNames("input-box", this.props.rounded)}
          placeholder={this.props.placeholder}
          required={this.props.required}
        />
        <ErrorMessage name={this.props.name}>
          {(msg) => <div className="text-sm text-red-600 mt-2">{msg}</div>}
        </ErrorMessage>
      </div>
    );
  }
}

export default React.memo(TextField);
