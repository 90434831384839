import { Component } from "react";
import DefaultTab, { ITab } from "../DefaultTab";

interface ITabRender {
  tab: ITab;
  render: () => void;
}

interface ITabRenderContainerProps {
  tabClassName?: string;
  tabs: ITabRender[];
  setActiveTab?: (idx: number) => void;
  activeTab?: number;
}

interface ITabRenderContainerState {
  activeTab: number;
}

export default class TabRenderContainer extends Component<
  ITabRenderContainerProps,
  ITabRenderContainerState
> {
  constructor(props: ITabRenderContainerProps) {
    super(props);
    this.state = {
      activeTab: this.props.activeTab ?? 0,
    };
  }

  componentDidUpdate(prevProps: ITabRenderContainerProps): void {
    if (prevProps.activeTab !== this.props.activeTab) {
      this.setState({
        activeTab: this.props.activeTab ?? 0,
      });
    }
  }

  render() {
    const { tabs, tabClassName } = this.props;
    return (
      <div>
        <DefaultTab
          className={tabClassName}
          activeTab={this.state.activeTab}
          activeTabState={(activeTab: number) => this.setState({ activeTab })}
          tabs={tabs.map((item, index) => ({
            ...item.tab,
            current: index === this.state.activeTab,
          }))}
        />
        {this.props.tabs[this.state.activeTab]?.render()}
      </div>
    );
  }
}
