import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import parseErrorMessage from "../../helpers/parseErrorMessage";
import { ProfileRepository } from "../../respository/ProfileRepository";

export interface IProfileState {
  isLoading: boolean;
  error?: Object;
  success?: boolean;
  response?: {
    statusCode: number;
    data: any;
  };
}

const initialState: IProfileState = {
  isLoading: false,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    reset(state) {
      state.response = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ProfileRepository.find.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(ProfileRepository.find.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.response = action.payload;
      })
      .addCase(ProfileRepository.find.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error;
        toast.error(parseErrorMessage(error.message));
      })
      .addCase(ProfileRepository.update.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(ProfileRepository.update.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.response = action.payload;
        toast.success("Successfully updated profile");
      })
      .addCase(ProfileRepository.update.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error;
        toast.error(parseErrorMessage(error.message));
      });
  },
});

export const { reset } = profileSlice.actions;

export default profileSlice.reducer;
