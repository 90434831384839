import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../builders/axiosInstance";
import { TProfileValues } from "../interfaces/ProfileInterfaces";

export class ProfileRepository {
  static find = createAsyncThunk("profile/find", async () => {
    try {
      return (await axiosInstance.get(`/users/me`)).data;
    } catch (error: any) {
      throw new Error(JSON.stringify(error.response.data));
    }
  });
  static update = createAsyncThunk(
    "profile/update",
    async (values: TProfileValues, __) => {
      try {
        const data = (
          await axiosInstance.patch(`/users/${values.id}`, values.data)
        ).data;
        this.refetch(data, __);
        return data;
      } catch (error: any) {
        throw new Error(JSON.stringify(error.response.data));
      }
    }
  );
  static refetch = async (_: any, __: any) => {
    __.dispatch(this.find());
  };
}
