import { sentenceCase } from "sentence-case";

export default function parseErrorMessage(value: any) {
  let message = JSON.parse(value).message;
  if (typeof message === "string" || message instanceof String) {
    return sentenceCase(message.toString());
  } else {
    return sentenceCase(message[0]);
  }
}
