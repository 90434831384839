import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginValues } from "../../interfaces/AuthInterfaces";
import { AuthRepository } from "../../respository/AuthRepository";
import { toast } from "react-toastify";
import parseErrorMessage from "../../helpers/parseErrorMessage";

export interface IAuthState {
  isLoading: boolean;
  error?: Object;
  success?: boolean;
}

// export type authSliceInitialType = {
//   isLoading: boolean;
//   error?: Object;
//   success?: boolean;
// };

const initialState: IAuthState = {
  isLoading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginUser(state, action: PayloadAction<LoginValues>) {
      state.isLoading = true;
    },
    createUser(state, action) {
      state.isLoading = true;
    },
    logoutUser(state) {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(AuthRepository.loginUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(AuthRepository.loginUser.fulfilled, (state, action) => {
        localStorage.setItem("access_token", action.payload.data.access_token);
        state.isLoading = false;
        state.success = true;
      })
      .addCase(AuthRepository.loginUser.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error;
        toast.error(parseErrorMessage(error.message));
      })
      .addCase(AuthRepository.createUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(AuthRepository.createUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(AuthRepository.createUser.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error;
        toast.error(parseErrorMessage(error.message));
      });
  },
});

export const { loginUser, createUser, logoutUser } = authSlice.actions;

export default authSlice.reducer;
