import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../builders/axiosInstance";

export class OrganizationRepository {
  static findAll = createAsyncThunk("organization/findAll", async () => {
    try {
      return (await axiosInstance.get("/organizations")).data;
    } catch (error: any) {
      throw new Error(JSON.stringify(error.response.data));
    }
  });
}
