import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../builders/axiosInstance";

export class ContestRepository {
  static findAll = createAsyncThunk("contests/findAll", async (id: string) => {
    try {
      return (await axiosInstance.get(`/organizations/${id}/contests/`)).data;
    } catch (error: any) {
      throw new Error(JSON.stringify(error.response.data));
    }
  });
  static create = createAsyncThunk("contests/create", async (values: any) => {
    try {
      return (
        await axiosInstance.post(
          `/organizations/${values.id}/contests/`,
          values.data
        )
      ).data;
    } catch (error: any) {
      throw new Error(JSON.stringify(error.response.data));
    }
  });
}
