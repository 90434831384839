export enum RoundedTextFieldTypes {
  Top = "rounded-t-md",
  None = "none mt-1",
  Bottom = "rounded-b-md",
  All = "rounded-md",
}
export type TextFieldProps = {
  name: string;
  value?: string;
  className?: string;
  label?: string;
  type?: string;
  placeholder?: string;
  rounded?: RoundedTextFieldTypes;
  required?: boolean;
  showLabel?: boolean;
  onChange?: (values: any) => void;
};
