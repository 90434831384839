import { Form, Formik } from "formik";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Button from "../../../components/Button/Button";
import TextField from "../../../components/Input/TextField";
import { contestInitialValues } from "../../../constants/initialValues";
import getJwtValue from "../../../helpers/jwtDecode";
import { RoundedTextFieldTypes } from "../../../interfaces/TextFieldProps";
import MainLayout from "../../../layout/MainLayout";
import { ContestRepository } from "../../../respository/ContestRepository";
import { IContestState } from "../../../store/slices/contest-slice";
import { ContestSchema } from "../../../validations/schemas/ContestSchema";
import withRouter, { IRouterProps } from "../../../wrappers/withRouter";

interface IMapStateToProps {
  contests: IContestState;
  router: IRouterProps;
}

interface ICreateContestProps extends IMapStateToProps {
  create: (values: any) => void;
}

interface ICreateContestState {}

class CreateContest extends Component<
  ICreateContestProps,
  ICreateContestState
> {
  componentDidUpdate(prevProps: ICreateContestProps) {
    if (prevProps.contests.success) {
      this.props.router.navigate("/");
    }
  }

  render() {
    const handleSubmit = async (values: any) => {
      await this.props.create({
        id: getJwtValue("org_id"),
        data: values,
      });
    };

    const { isLoading } = this.props.contests;

    return (
      <Fragment>
        <MainLayout title="Create Contest">
          <div className="max-w-xl">
            <Formik
              initialValues={contestInitialValues}
              onSubmit={handleSubmit}
              validationSchema={ContestSchema.contestCreateSchema}
            >
              <Form className="mt-8 space-y-6">
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <TextField
                      name="name"
                      label="Contest Name"
                      showLabel={true}
                      required={false}
                      type="text"
                      rounded={RoundedTextFieldTypes.All}
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <TextField
                      name="start_at"
                      label="Start From"
                      showLabel={true}
                      required={false}
                      type="date"
                      rounded={RoundedTextFieldTypes.All}
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <TextField
                      name="end_at"
                      label="End At"
                      showLabel={true}
                      required={false}
                      type="date"
                      rounded={RoundedTextFieldTypes.All}
                    />
                  </div>

                  <div className="sm:col-span-6 mt-2">
                    <Button
                      type="submit"
                      label="Submit"
                      isLoading={isLoading}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </MainLayout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: IMapStateToProps) => {
  return {
    contests: state.contests,
  };
};

const mapDispatchToProps = {
  create: ContestRepository.create,
};

export default React.memo(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateContest))
);
