import React, { Component } from "react";
import classNames from "../../helpers/classNameMerger";
import DefaultLoading from "../Loading/DefaultLoading";

type ButtonType = "button" | "submit" | "reset";

export type IButtonType = {
  label: string;
  type: ButtonType;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
};

class Button extends Component<IButtonType> {
  render() {
    const { label, type, isLoading, disabled } = this.props;
    return (
      <button
        type={type}
        className={classNames(
          "btn",
          this.props.className,
          disabled ? "cursor-wait" : ""
        )}
        disabled={disabled}
      >
        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
          {isLoading && <DefaultLoading />}
        </span>
        {label}
      </button>
    );
  }
}

export default React.memo(Button);
