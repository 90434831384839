import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import parseErrorMessage from "../../helpers/parseErrorMessage";
import { SubmissionRepository } from "../../respository/SubmissionRepository";

export interface ISubmissionState {
  isLoading: boolean;
  error?: Object;
  success?: boolean;
  response?: {
    statusCode: number;
    data: any;
  };
}

const initialState: ISubmissionState = {
  isLoading: false,
};

const submissionSlice = createSlice({
  name: "submission",
  initialState,
  reducers: {
    reset(state) {
      state.success = false;
      state.response = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(SubmissionRepository.create.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(SubmissionRepository.create.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.response = action.payload;
      })
      .addCase(SubmissionRepository.create.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error;
        toast.error(parseErrorMessage(error.message));
      });
  },
});

export const { reset } = submissionSlice.actions;

export default submissionSlice.reducer;
