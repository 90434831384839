import { SearchIcon } from "@heroicons/react/outline";
import { Component } from "react";
import { TextFieldProps } from "../../interfaces/TextFieldProps";

class TextFieldWithIcon extends Component<TextFieldProps> {
  render() {
    return (
      <div>
        <label
          htmlFor={this.props.name}
          className="block text-sm font-medium text-gray-700"
        >
          {this.props.label}
        </label>
        <div className="mt-1 flex rounded-md">
          <div className="relative flex items-stretch flex-grow focus-within:z-10">
            <input
              id={this.props.name}
              name={this.props.name}
              type={this.props.type}
              className="input-with-icon"
              placeholder={this.props.placeholder}
              onChange={this.props.onChange}
              value={this.props.value}
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <SearchIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TextFieldWithIcon;
