import {
  CheckIcon,
  ClockIcon,
  FlagIcon,
  XIcon,
} from "@heroicons/react/outline";
import { Component } from "react";
import { parseDate } from "../../helpers/parseDate";

export interface IStackCard {
  data: {
    id: string;
    name: string;
    difficulty: string;
    department: string;
    test_case: string;
    created_at: string;
    completed: number;
    language: string[];
  };
}

class StackedListCard extends Component<IStackCard> {
  render() {
    const data = this.props.data;
    return (
      <div className="block hover:bg-gray-100 transition-colors duration-200 rounded">
        <div className="px-2 py-4 sm:px-6">
          <div className="flex items-center justify-between">
            <p className="text-sm font-medium text-indigo-600 truncate">
              {data.name}
            </p>
            <div className="ml-2 flex-shrink-0 flex space-x-1.5">
              {data.language.map((language: any, index) => (
                <p
                  key={`badge-${index}-${language.extension}`}
                  className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 uppercase"
                >
                  {language.extension}
                </p>
              ))}
            </div>
          </div>
          <div className="mt-2 sm:flex sm:justify-between">
            <div className="sm:flex">
              <p className="flex items-center text-sm text-gray-500">
                {data?.completed ? (
                  <CheckIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                    aria-hidden="true"
                  />
                ) : (
                  <XIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                )}

                {data?.completed ? "Completed" : "Not Completed"}
              </p>
              <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                <ClockIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                {parseDate(data?.created_at)}
              </p>
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
              <FlagIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <p>
                <span>{data.difficulty}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StackedListCard;
