import { Form, Formik } from "formik";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Button from "../../components/Button/Button";
import TextField from "../../components/Input/TextField";
import { filterValues } from "../../helpers/filterValues";
import { getImage } from "../../helpers/getImageUtils";
import getJwtValue from "../../helpers/jwtDecode";
import { objectToFormData } from "../../helpers/objectToFormData";
import { TProfileValues } from "../../interfaces/ProfileInterfaces";
import { RoundedTextFieldTypes } from "../../interfaces/TextFieldProps";
import MainLayout from "../../layout/MainLayout";
import { ProfileRepository } from "../../respository/ProfileRepository";
import { IProfileState } from "../../store/slices/profile-slice";
import { ProfileSchema } from "../../validations/schemas/ProfileSchema";
import withRouter, { IRouterProps } from "../../wrappers/withRouter";

interface IMapStateToProps {
  profile: IProfileState;
  router: IRouterProps;
}

interface IProfileProps extends IMapStateToProps {
  find: () => void;
  update: (values: TProfileValues) => void;
}

class Profile extends Component<IProfileProps> {
  componentDidMount() {
    this.props.find();
  }

  render() {
    const handleSubmit = (values: any) => {
      this.props.update({
        id: getJwtValue("id"),
        data: objectToFormData(values),
      });
    };

    const { isLoading, success, response } = this.props.profile;
    return (
      <Fragment>
        <MainLayout title="Profile">
          <div className="max-w-xl">
            <div className="pt-4 space-y-6 sm:pt-6 sm:space-y-5">
              {success && (
                <Formik
                  initialValues={filterValues(response?.data)}
                  validationSchema={ProfileSchema.profileUpdateSchema}
                  onSubmit={handleSubmit}
                >
                  {({ values, setFieldValue }) => (
                    <Form className="mt-0 space-y-6">
                      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-6">
                          <img
                            className="h-16 w-16 rounded-full"
                            src={getImage(values.avatar)}
                            alt="profile"
                          />
                        </div>
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="cover-photo"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Profile Picture
                          </label>
                          <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                            <div className="space-y-1 text-center">
                              <svg
                                className="mx-auto h-12 w-12 text-gray-400"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 48 48"
                                aria-hidden="true"
                              >
                                <path
                                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              <div className="flex text-sm text-gray-600">
                                <label
                                  htmlFor="file-upload"
                                  className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                >
                                  <span>Upload a file</span>
                                  <input
                                    id="file-upload"
                                    name="avatar"
                                    type="file"
                                    className="sr-only"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "avatar",
                                        event.currentTarget.files
                                          ? event.currentTarget.files[0]
                                          : null
                                      );
                                    }}
                                  />
                                </label>
                                <p className="pl-1">or drag and drop</p>
                              </div>
                              <p className="text-xs text-gray-500">
                                PNG, JPG, GIF up to 10MB
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="sm:col-span-6">
                          <TextField
                            name="number"
                            label="Phone Number"
                            showLabel={true}
                            required={false}
                            type="text"
                            rounded={RoundedTextFieldTypes.All}
                          />
                        </div>
                        <div className="sm:col-span-6">
                          <TextField
                            name="bio"
                            label="Bio"
                            showLabel={true}
                            required={false}
                            type="text"
                            rounded={RoundedTextFieldTypes.All}
                          />
                        </div>
                        <div className="sm:col-span-6 mt-2">
                          <Button
                            type="submit"
                            label="Submit"
                            isLoading={isLoading}
                            disabled={isLoading}
                          />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </MainLayout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: IMapStateToProps) => {
  return {
    profile: state.profile,
  };
};

const mapDispatchToProps = {
  find: ProfileRepository.find,
  update: ProfileRepository.update,
};

export default React.memo(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile))
);
