export const filterValues = (obj: any) => {
  const newObj: any = {};
  for (const key in obj) {
    if (obj[key] === null) {
      newObj[key] = "";
    } else {
      newObj[key] = `${obj[key]}`;
    }
  }
  return newObj;
};
