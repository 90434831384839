import {
  CollectionIcon,
  CubeTransparentIcon,
  HomeIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import LeftToRightTransition from "../../animations/Transitions/LeftToRightTransition";
import classNames from "../../helpers/classNameMerger";

export interface ISidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (sidebarOpen: boolean) => void;
}

export interface ISidebarState {
  sidebarOpen: boolean;
}

class Sidebar extends Component<ISidebarProps, ISidebarState> {
  constructor(props: ISidebarProps) {
    super(props);
    this.state = {
      sidebarOpen: props.sidebarOpen,
    };
  }

  render() {
    const navigation = [
      { name: "Companies", href: "/", icon: HomeIcon, current: true },
      { name: "Profile", href: "/profile", icon: UsersIcon, current: false },
      {
        name: "Create Contest",
        href: "/contests/create",
        icon: CollectionIcon,
        current: false,
      },
      {
        name: "Create Challenge",
        href: "/challenges/create",
        icon: CubeTransparentIcon,
        current: false,
      },
    ];

    return (
      <React.Fragment>
        <LeftToRightTransition
          open={this.props.sidebarOpen}
          setOpen={this.props.setSidebarOpen}
        >
          <React.Fragment>
            {/* <div className="flex-shrink-0 px-4 flex items-center">
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
                alt="Workflow"
              />
            </div> */}
            <div className="mt-5 flex-1 overflow-y-auto">
              <nav className="px-2 space-y-1">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-100 text-gray-900"
                        : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                      "group rounded-md py-2 px-2 flex items-center text-base font-medium"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "mr-4 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                ))}
              </nav>
            </div>
          </React.Fragment>
        </LeftToRightTransition>
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 overflow-hidden">
          <div className="border-r border-gray-200 pt-4 flex flex-col flex-grow bg-white overflow-y-auto">
            {/* <div className="flex-shrink-0 px-4 flex items-center">
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
                alt=""
              />
            </div> */}
            <div className="flex-grow mt-5 flex flex-col">
              <nav className="flex-1 px-2 pb-4 space-y-1">
                {navigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className={({ isActive }) =>
                      classNames(
                        isActive
                          ? "bg-gray-100 text-gray-900 inner-icon-active"
                          : "text-gray-600 hover:bg-gray-50 hover:text-gray-900 inner-icon-inactive",
                        "group rounded-md py-2 px-2 flex items-center text-sm font-medium"
                      )
                    }
                  >
                    <item.icon
                      className={classNames("mr-3 flex-shrink-0 h-6 w-6")}
                      aria-hidden="true"
                    />
                    {item.name}
                  </NavLink>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default React.memo(Sidebar);
