export function toQueryParams(params: any) {
  const query = Object.keys(removeEmptyKeys(params))
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join("&");
  return query;
}

export function removeEmptyKeys(obj: any) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === "") {
      delete obj[key];
    }
  });
  return obj;
}
