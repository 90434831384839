import moment from "moment";
import React, { Component } from "react";
import { Fade } from "react-awesome-reveal";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import ContestCard from "../../components/Card/ContestCard";
import MainLayout from "../../layout/MainLayout";
import { ContestRepository } from "../../respository/ContestRepository";
import { IContestState } from "../../store/slices/contest-slice";
import withRouter, { IRouterProps } from "../../wrappers/withRouter";

interface IMapStateToProps {
  contests: IContestState;
  router: IRouterProps;
}

interface IContestsProps extends IMapStateToProps {
  findAll: (id: string) => void;
}

interface IContestsState {
  filteredItems: any[];
}

class Contest extends Component<IContestsProps, IContestsState> {
  constructor(props: IContestsProps) {
    super(props);
    this.state = {
      filteredItems: [],
    };
  }

  componentDidMount() {
    this.props.findAll(`${this.props.router.params.id}`);
  }

  render() {
    const { success, response } = this.props.contests;
    const redirect = (id: string, start_time: string, end_time: string) => {
      if (moment().isBefore(start_time)) {
        toast.error("Contest has not started yet");
      } else if (moment().isAfter(end_time)) {
        toast.error("Contest has ended");
      } else {
        this.props.router.navigate(`/challenges/${id}`);
      }
    };

    function sortItems(id: string) {
      const values: any[] = [...response.data];
      switch (id) {
        case "0":
          return values;
        case "1":
          return values.sort((a: any, b: any) => a?.deviation - b?.deviation);
        case "2":
          return values.sort((a: any, b: any) => b?.deviation - a?.deviation);
        default:
          return [];
      }
    }

    const copyData: any[] = success
      ? this.state.filteredItems.length > 0
        ? this.state.filteredItems
        : response.data
      : [];

    return (
      <MainLayout title="Contests">
        <div className="flex w-full justify-end mb-2">
          <select
            name="sort"
            className="h-full rounded-md border-transparent bg-transparent pl-2 pr-7 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            onChange={(e) => {
              console.log(sortItems(e.target.value));
              this.setState({
                filteredItems: sortItems(e.target.value),
              });
            }}
          >
            <option value={"0"}>Default</option>
            <option value={"1"}>Easiest</option>
            <option value={"2"}>Hardest</option>
          </select>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
          <Fade cascade triggerOnce damping={0.2}>
            {success &&
              copyData.map((_: any) => (
                <div
                  key={_.id}
                  onClick={() => redirect(_.id, _.start_at, _.end_at)}
                >
                  <ContestCard
                    name={_.name}
                    language={_.language}
                    challenges={_.challenge}
                    start_time={_.start_at}
                    end_time={_.end_at}
                    deviation={_.deviation}
                  />
                </div>
              ))}
          </Fade>
        </div>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state: IMapStateToProps) => {
  return {
    contests: state.contests,
  };
};

const mapDispatchToProps = {
  findAll: ContestRepository.findAll,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(React.memo(Contest))
);
