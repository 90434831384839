import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../builders/axiosInstance";
import { LoginValues } from "../interfaces/AuthInterfaces";

export class AuthRepository {
  static loginUser = createAsyncThunk(
    "auth/loginUser",
    async (values: LoginValues) => {
      try {
        return (await axiosInstance.post("/auth/login", values)).data;
      } catch (error: any) {
        throw new Error(JSON.stringify(error.response.data));
      }
    }
  );

  static createUser = createAsyncThunk(
    "auth/createUser",
    async (values: LoginValues) => {
      try {
        return (await axiosInstance.post("/auth/create", values)).data;
      } catch (error: any) {
        throw new Error(JSON.stringify(error.response.data));
      }
    }
  );
}
