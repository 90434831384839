import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../builders/axiosInstance";

export class LanguageRepository {
  static findAll = createAsyncThunk("language/findAll", async () => {
    try {
      return (await axiosInstance.get(`/languages`)).data;
    } catch (error: any) {
      throw new Error(JSON.stringify(error.response.data));
    }
  });
}
