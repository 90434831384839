import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import authSlice from "./slices/auth-slice";
import challengeSlice from "./slices/challenge-slice";
import contestSlice from "./slices/contest-slice";
import languageSlice from "./slices/language-slice";
import organizationSlice from "./slices/organization-slice";
import profileSlice from "./slices/profile-slice";
import submissionSlice from "./slices/submission-slice";

const store = configureStore({
  reducer: combineReducers({
    auth: authSlice,
    organization: organizationSlice,
    contests: contestSlice,
    challenges: challengeSlice,
    submission: submissionSlice,
    languages: languageSlice,
    profile: profileSlice,
  }),
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(routerMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
// Export a hook that can be reused to resolve types

// export const history = createReduxHistory(store);

export default store;
