import {
  BanIcon,
  ClockIcon,
  CodeIcon,
  TicketIcon,
} from "@heroicons/react/outline";
import moment from "moment";
import { Component } from "react";
import {
  checkStatusDate,
  StatusOfContest,
} from "../../helpers/checkStatusDate";
import { parseDate } from "../../helpers/parseDate";
import StatusBadge, { StatusBadgeType } from "../Badge/StatusBadge";

type ContestCardProps = {
  name: string;
  language: string;
  challenges: number;
  start_time: string;
  end_time: string;
  deviation: number;
};

export default class ContestCard extends Component<ContestCardProps> {
  render() {
    const contestStatus = () =>
      checkStatusDate(this.props.start_time, this.props.end_time);

    return (
      <div className="rounded-md shadow bg-white p-2 cursor-pointer transition-shadow hover:shadow-md">
        <div className="flex items-center justify-between text-sm">
          <span className="p-1 bg-slate-100 text-gray-500 rounded-md px-2 flex space-x-2">
            <span>{"Easy"}</span>
          </span>
          <span className="p-1 bg-slate-100 text-gray-500 rounded-md px-2 flex space-x-2">
            <span>{this.props.challenges}</span>
            <CodeIcon className="w-4" />
          </span>
        </div>
        <div className="py-6 truncate">
          <span className="text-black text-lg font-bold">
            {this.props.name}
          </span>
        </div>
        <div className="flex items-center justify-between">
          {contestStatus() === StatusOfContest.Upcoming ? (
            <StatusBadge
              type={StatusBadgeType.Upcoming}
              icon={ClockIcon}
              label={`${parseDate(this.props.start_time)} :${" "}
                ${parseDate(this.props.end_time)}`}
            />
          ) : contestStatus() === StatusOfContest.Running ? (
            <StatusBadge
              type={StatusBadgeType.Running}
              icon={TicketIcon}
              label="Running"
              labelClass="text-white"
            />
          ) : (
            <StatusBadge
              type={StatusBadgeType.Expired}
              icon={BanIcon}
              label="Expired"
              labelClass="text-white"
            />
          )}

          <div className="tooltip">
            <StatusBadge
              type={StatusBadgeType.Time}
              icon={ClockIcon}
              label={moment
                .utc(
                  moment
                    .duration(this.props.deviation, "seconds")
                    .as("milliseconds")
                )
                .format("HH:mm:ss")}
              labelClass="text-white"
            />
            <span className="tooltiptext text-sm">
              Lower the Time indicates the easy difficulty level
            </span>
          </div>
        </div>
      </div>
    );
  }
}
