import React, { Component } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar/Sidebar";

interface IMainLayoutProps {
  title: string;
  fixPadding?: boolean;
}

interface IMainLayoutState {
  sidebarOpen: boolean;
}

export default class MainLayout extends Component<
  IMainLayoutProps,
  IMainLayoutState
> {
  constructor(props: IMainLayoutProps) {
    super(props);
    this.state = {
      sidebarOpen: false,
    };
  }
  render() {
    const setSidebarOpen = (sidebarOpen: boolean) => {
      this.setState({ sidebarOpen: sidebarOpen });
    };

    return (
      <div className="relative">
        <Sidebar
          sidebarOpen={this.state.sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <div className="md:pl-64">
          <div className="max-w-6xl mx-auto flex flex-col md:px-8 xl:px-0">
            <Header setSidebarOpen={setSidebarOpen} />
            <main className="flex-1">
              <div className={!this.props.fixPadding ? "py-6" : ""}>
                <div className="px-4 sm:px-6 md:px-0">
                  <h1 className="text-2xl font-semibold text-gray-900">
                    {this.props.title}
                  </h1>
                </div>
                <div className="px-4 sm:px-6 md:px-0">
                  <div className={!this.props.fixPadding ? "py-4" : ""}>
                    {/* <div className="h-96 border-4 border-dashed border-gray-200 rounded-lg" /> */}
                    {this.props.children}
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
