import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import parseErrorMessage from "../../helpers/parseErrorMessage";
import { ChallengeRepository } from "../../respository/ChallengeRepository";

export interface ISingleChallenge {
  isLoading: boolean;
  error?: Object;
  success?: boolean;
  response?: {
    statusCode: number;
    data: any;
  };
}

export interface IChallengeState {
  isLoading: boolean;
  error?: Object;
  success?: boolean;
  response?: {
    statusCode: number;
    data: any[];
  };
  challenge: ISingleChallenge;
}

const initialState: IChallengeState = {
  isLoading: false,
  success: false,
  challenge: {
    isLoading: false,
  },
};

const challengeSlice = createSlice({
  name: "challenges",
  initialState,
  reducers: {
    reset(state) {
      state.success = false;
      state.response = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ChallengeRepository.create.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(ChallengeRepository.create.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(ChallengeRepository.create.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error;
        toast.error(parseErrorMessage(error.message));
      })
      .addCase(ChallengeRepository.findAll.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(ChallengeRepository.findAll.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.response = action.payload;
      })
      .addCase(ChallengeRepository.findAll.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error;
        toast.error(parseErrorMessage(error.message));
      })
      .addCase(ChallengeRepository.filter.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(ChallengeRepository.filter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.response = action.payload;
      })
      .addCase(ChallengeRepository.filter.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error;
        toast.error(parseErrorMessage(error.message));
      })
      .addCase(ChallengeRepository.findOneById.pending, (state, action) => {
        state.challenge.response = undefined;
        state.challenge.success = false;
        state.challenge.isLoading = true;
      })
      .addCase(ChallengeRepository.findOneById.fulfilled, (state, action) => {
        state.challenge.isLoading = false;
        state.challenge.success = true;
        state.challenge.response = action.payload;
      })
      .addCase(ChallengeRepository.findOneById.rejected, (state, { error }) => {
        state.challenge.isLoading = false;
        state.challenge.error = error;
        toast.error(parseErrorMessage(error.message));
      });
  },
});

export const { reset } = challengeSlice.actions;

export default challengeSlice.reducer;
