import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import parseErrorMessage from "../../helpers/parseErrorMessage";
import { LanguageRepository } from "../../respository/LanguageRepository";

export interface ILanguageState {
  isLoading: boolean;
  error?: Object;
  success?: boolean;
  response?: {
    statusCode: number;
    data: any;
  };
}

const initialState: ILanguageState = {
  isLoading: false,
};

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    reset(state) {
      state.response = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LanguageRepository.findAll.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(LanguageRepository.findAll.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.response = action.payload;
      })
      .addCase(LanguageRepository.findAll.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error;
        toast.error(parseErrorMessage(error.message));
      });
  },
});

export const { reset } = languageSlice.actions;

export default languageSlice.reducer;
