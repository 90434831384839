import jwt_decode from "jwt-decode";

const getJwtValue = (key: string) => {
  const decoded = jwt_decode<any>(localStorage.getItem("access_token") ?? "")[
    key
  ];
  if (decoded === null) {
    throw new Error("Invalid token");
  }
  return decoded;
};

export default getJwtValue;
