import { Component, Fragment } from "react";
import { connect } from "react-redux";
import SelectField from "../../components/Input/SelectField";
import TextFieldWithIcon from "../../components/Input/TextFieldWithIcon";
import MainLayout from "../../layout/MainLayout";
import StackedListCard from "../../components/List/StackedListCard";
import withRouter, { IRouterProps } from "../../wrappers/withRouter";
import { IChallengeState } from "../../store/slices/challenge-slice";
import { ChallengeRepository } from "../../respository/ChallengeRepository";
import { Form, Formik } from "formik";
import {
  ChallengeFilterPrams,
  ChallengeFilterType,
  initialValueFilterChallenges,
} from "../../interfaces/ChallengeInterfaces";
import Button from "../../components/Button/Button";
import { toast } from "react-toastify";
import { Fade } from "react-awesome-reveal";

interface IMapStateToProps {
  challenges: IChallengeState;
  router: IRouterProps;
}

interface IChallengesProps extends IMapStateToProps {
  findAll: (id: string) => void;
  filter: (values: ChallengeFilterPrams) => void;
  reset: () => void;
}

interface IChallengesState {}

class Challenge extends Component<IChallengesProps, IChallengesState> {
  componentDidMount() {
    this.props.findAll(`${this.props.router.params.id}`);
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const { success, isLoading, response } = this.props.challenges;
    const { id } = this.props.router.params;

    const handleSubmit = async (values: ChallengeFilterType) => {
      this.props.filter({
        id: `${this.props.router.params.id}`,
        params: values,
      });
    };

    const handleReset = async () => {
      this.props.findAll(`${this.props.router.params.id}`);
    };

    const navigate = (to: string, index: number) => {
      if (this.props.challenges.response!.data[index].completed) {
        toast.success("Challenge is Already Completed");
      } else {
        this.props.router.navigate(to);
      }
    };

    return (
      <Fragment>
        <MainLayout title="Challenges">
          <div className="flex flex-col lg:flex-row z-0 overflow-hidden min-h-screen">
            <aside className="lg:flex-initial lg:pt-2 mb-6 lg:mb-0 lg:w-96 lg:border-r border-gray-200 overflow-y-auto pl-2 pr-8">
              <Formik
                initialValues={initialValueFilterChallenges}
                onSubmit={handleSubmit}
                onReset={handleReset}
              >
                {({ values, handleChange }) => (
                  <Form className="space-y-4">
                    <TextFieldWithIcon
                      name="search"
                      label="Search"
                      type="text"
                      onChange={handleChange}
                      value={values.search || ""}
                    />
                    <SelectField
                      label="Sort By"
                      name="created_at"
                      options={[
                        {
                          label: "Newest",
                          value: "ASC",
                        },
                        {
                          label: "Oldest",
                          value: "DESC",
                        },
                      ]}
                    />
                    <SelectField
                      label="Languages"
                      name="language"
                      options={[
                        {
                          label: "Javascript",
                          value: "js",
                        },
                        {
                          label: "Python",
                          value: "py",
                        },
                      ]}
                    />
                    <div className="flex items-center justify-center space-x-4 mt-2 lg:mt-4">
                      <Button
                        type="submit"
                        label="Filter"
                        isLoading={isLoading}
                        disabled={isLoading}
                      />
                      <Button
                        type="reset"
                        label="Reset"
                        // isLoading={isSubmitting}
                        // disabled={isSubmitting}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </aside>
            <main className="flex-1 w-full z-0 overflow-y-auto focus:outline-none sm:px-4 mt-4 sm:mt-0">
              <div>
                {success &&
                  response?.data.map((data: any, index: number) => (
                    <div
                      className="cursor-pointer"
                      key={data.id}
                      onClick={() =>
                        navigate(`/contests/${id}/challenge/${data.id}`, index)
                      }
                    >
                      <Fade cascade triggerOnce damping={0.2}>
                        <StackedListCard key={data.id} data={data} />
                      </Fade>
                    </div>
                  ))}

                {response?.data.length === 0 && (
                  <div className="text-center text-sm text-gray-400 my-5">
                    No Challenges Found
                  </div>
                )}
              </div>
            </main>
          </div>
        </MainLayout>

        {/* {this.props.challenges.success && (
          <AnimatedModal
            isLoading={this.props.challenges.challenge.isLoading}
            title={"You Completed this Contest"}
            defaultOpen={false}
            open={
              this.props.challenges.response!.data.length > 0
                ? this.props.challenges.response?.data.every(
                    (data) => data.completed
                  )
                : false
            }
            actionBtn={() => {}}
            actionBtnText={"Go Back"}
          />
        )} */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: IMapStateToProps) => ({
  challenges: state.challenges,
});

const mapDispatchToProps = {
  findAll: ChallengeRepository.findAll,
  filter: ChallengeRepository.filter,
  reset: ChallengeRepository.reset,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Challenge)
);
