import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CompanyCard from "../../components/Card/CompanyCard";
import MainLayout from "../../layout/MainLayout";
import { OrganizationRepository } from "../../respository/OrganizationRepository";
import { IOrganizationState } from "../../store/slices/organization-slice";
import withRouter, { IRouterProps } from "../../wrappers/withRouter";
import { Fade } from "react-awesome-reveal";

interface IMapStateToProps {
  organization: IOrganizationState;
  router: IRouterProps;
}

interface IHomeProps extends IMapStateToProps {
  findAll: () => void;
}

interface IHomeState {}

class Home extends Component<IHomeProps, IHomeState> {
  componentDidMount() {
    this.props.findAll();
  }

  render() {
    const { success, response } = this.props.organization;

    return (
      <MainLayout title="Companies">
        {success && (
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4">
            <Fade cascade triggerOnce damping={0.2}>
              {response.data.map((_: any) => (
                <Link to={`contests/${_.id}`} key={_.id}>
                  <CompanyCard name={_.name} src={_.image} />
                </Link>
              ))}
            </Fade>
          </div>
        )}
      </MainLayout>
    );
  }
}

const mapStateToProps = (state: IMapStateToProps) => {
  return {
    organization: state.organization,
  };
};

const mapDispatchToProps = {
  findAll: OrganizationRepository.findAll,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(React.memo(Home))
);
