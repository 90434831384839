import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../builders/axiosInstance";
import { toQueryParams } from "../helpers/objectToQueryPrams";
import {
  ChallengeFilterPrams,
  TChallengeValues,
} from "../interfaces/ChallengeInterfaces";
import { reset } from "../store/slices/challenge-slice";

export class ChallengeRepository {
  static create = createAsyncThunk("challenge/create", async (values: any) => {
    try {
      return (
        await axiosInstance.post(
          `/contests/${values.id}/challenges`,
          values.data
        )
      ).data;
    } catch (error: any) {
      throw new Error(JSON.stringify(error.response.data));
    }
  });
  static findAll = createAsyncThunk("challenge/findAll", async (id: string) => {
    try {
      return (await axiosInstance.get(`/contests/${id}/challenges`)).data;
    } catch (error: any) {
      throw new Error(JSON.stringify(error.response.data));
    }
  });
  static findOneById = createAsyncThunk(
    "challenge/findOneById",
    async (values: TChallengeValues) => {
      try {
        return (
          await axiosInstance.get(
            `/contests/${values.id}/challenges/${values.challengeId}`
          )
        ).data;
      } catch (error: any) {
        throw new Error(JSON.stringify(error.response.data));
      }
    }
  );
  static filter = createAsyncThunk(
    "challenge/filter",
    async (values: ChallengeFilterPrams) => {
      try {
        const queryParams = toQueryParams(values.params);
        return (
          await axiosInstance.get(
            `/contests/${values.id}/challenges/?${queryParams}`
          )
        ).data;
      } catch (error: any) {
        throw new Error(JSON.stringify(error.response.data));
      }
    }
  );
  static reset = createAsyncThunk("challenge/reset", async (_, __) => {
    __.dispatch(reset());
  });
}
