import React, { Component } from "react";
import { Link } from "react-router-dom";
import TextField from "../../../components/Input/TextField";
import IconButton from "../../../components/Button/IconButton";
import { Form, Formik } from "formik";
import AuthLayout from "../../../layout/AuthLayout";
import { RegisterValues } from "../../../interfaces/AuthInterfaces";
import { AuthRepository } from "../../../respository/AuthRepository";
import { connect } from "react-redux";
import { createInitialValues } from "../../../constants/initialValues";
import { IAuthState } from "../../../store/slices/auth-slice";
import withRouter, { IRouterProps } from "../../../wrappers/withRouter";
import { RoundedTextFieldTypes } from "../../../interfaces/TextFieldProps";

interface IMapStateToProps {
  auth: IAuthState;
  router: IRouterProps;
}

interface IRegistrationProps extends IMapStateToProps {
  createUser: (values: RegisterValues) => void;
}

class Register extends Component<IRegistrationProps, IAuthState> {
  componentDidMount() {
    this.props.auth.success && this.props.router.navigate("login");
  }

  render() {
    const { isLoading } = this.props.auth;

    const handleCreate = (values: RegisterValues) => {
      this.props.createUser(values);
    };

    return (
      <AuthLayout title="Sign up to your account">
        <Formik initialValues={createInitialValues} onSubmit={handleCreate}>
          <Form className="mt-8 space-y-6">
            <div className="rounded-md shadow-sm -space-y-px">
              <TextField
                name="username"
                type="text"
                label="Username"
                placeholder="Username"
                required={true}
                rounded={RoundedTextFieldTypes.Top}
              />
              <TextField
                name="email"
                type="email"
                label="Email Address"
                required={true}
                placeholder="Email Address"
                rounded={RoundedTextFieldTypes.None}
              />
              <TextField
                name="password"
                type="password"
                label="Password"
                required={true}
                placeholder="Password"
                rounded={RoundedTextFieldTypes.Bottom}
              />
            </div>
            <div className="flex items-center justify-between">
              <Link
                to="/"
                className="font-medium text-indigo-600 hover:text-indigo-500 text-sm"
              >
                Forgot your password?
              </Link>
            </div>

            <div>
              <IconButton
                label="Sign Up"
                type="submit"
                isLoading={isLoading}
                disabled={isLoading}
              />
            </div>
          </Form>
        </Formik>
      </AuthLayout>
    );
  }
}

const mapStateToProps = (state: IMapStateToProps) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = { createUser: AuthRepository.createUser };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(React.memo(Register))
);
