import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import MainLayout from "../../layout/MainLayout";
import withRouter, { IRouterProps } from "../../wrappers/withRouter";
import { IChallengeState } from "../../store/slices/challenge-slice";
import { ChallengeRepository } from "../../respository/ChallengeRepository";
import Editor from "@monaco-editor/react";
import Button from "../../components/Button/Button";
import SelectField, { FlexType } from "../../components/Input/SelectField";
import { Form, Formik } from "formik";
import TabRenderContainer from "../../components/Tabs/lib/TabRenderContainer";
import { TChallengeValues } from "../../interfaces/ChallengeInterfaces";
import { SubmissionRepository } from "../../respository/SubmissionRepository";
import { TSubmissionValues } from "../../interfaces/SubmissionInterfaces";
import OutputResult from "../../components/Output/OutputResult";
import { ISubmissionState } from "../../store/slices/submission-slice";
import AnimatedModal from "../../components/Modal/AnimatedModal";
import { toast } from "react-toastify";

interface IMapStateToProps {
  submission: ISubmissionState;
  challenges: IChallengeState;
  router: IRouterProps;
}

interface IChallengesProps extends IMapStateToProps {
  findOneById: (values: TChallengeValues) => void;
  create: (values: TSubmissionValues) => void;
  reset: () => void;
}

interface IChallengesState {
  code: string;
  activeTab: number;
  startTime: Date;
}

class Test extends Component<IChallengesProps, IChallengesState> {
  constructor(props: any) {
    super(props);
    this.state = {
      code: "",
      activeTab: 0,
      startTime: new Date(),
    };
  }

  componentDidMount() {
    this.props.findOneById({
      id: `${this.props.router.params.id}`,
      challengeId: `${this.props.router.params.challengeId}`,
    });
  }

  render() {
    const { success, response } = this.props.challenges.challenge;

    const handleSubmission = async (values: any) => {
      this.setState({
        ...this.state,
        activeTab: 1,
      });
      toast.info("Testing code with test cases", {
        position: "top-right",
      });
      const data = {
        ...values,
        code: this.state.code,
        timeTaken:
          (new Date().getTime() - this.state.startTime.getTime()) / 1000,
      };
      this.props.create({
        id: `${this.props.router.params.challengeId}`,
        data,
      });
    };

    const onChange = (value: any, e: any) => {
      this.setState({
        code: `${value}`,
      });
    };

    const onMount = () => {
      this.setState({
        code: this.props.challenges.challenge.response?.data.default_code,
      });
    };

    const goBack = () => {
      this.props.reset();
      this.props.router.navigate(`/challenges/${this.props.router.params.id}`);
    };

    return (
      <Fragment>
        <MainLayout title="" fixPadding={true}>
          <div className="flex-1 flex-col-reverse sm:flex-row relative z-0 flex overflow-hidden min-h-screen">
            {success && (
              <Fragment>
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last mt-4 sm:mt-0">
                  <Formik
                    initialValues={{
                      language: response?.data?.language[0].id ?? 0,
                    }}
                    onSubmit={handleSubmission}
                  >
                    <Form>
                      <div className="py-4 bg-slate-50 px-4 rounded-b-md">
                        <div className="flex items-center justify-center">
                          <SelectField
                            flexType={FlexType.Row}
                            label="Languages"
                            name="language"
                            options={
                              response?.data?.language.map((language: any) => ({
                                label: language.name,
                                value: language.id,
                              })) || []

                              // [
                              // {
                              //   label: "Javascript",
                              //   value: "js",
                              // },
                              // {
                              //   label: "Python",
                              //   value: "py",
                              // },
                              // ]
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <Editor
                          className="lg:min-h-[65vh] mt-4"
                          defaultLanguage="javascript"
                          defaultValue={
                            response?.data?.default_code
                              ? `// Do not Edit Function Name of this Code\n${response?.data?.default_code}`
                              : ""
                          }
                          onMount={onMount}
                          onChange={onChange}
                        />
                        <div className="mt-8 flex space-x-4 justify-end items-center">
                          {/* <Button className="w-2/12" type="button" label="Test" /> */}
                          <Button
                            className="w-2/12"
                            type="submit"
                            isLoading={this.props.submission.isLoading}
                            label="Submit"
                          />
                        </div>
                      </div>
                    </Form>
                  </Formik>
                </main>
                <aside className="relative xl:order-first xl:flex xl:flex-col flex-shrink-0 lg:w-96 lg:border-r border-gray-200 overflow-y-auto">
                  {/* <span className="border-b-2 py-4 mb-4">Instructions</span> */}

                  <TabRenderContainer
                    tabClassName="py-5"
                    activeTab={this.state.activeTab}
                    tabs={[
                      {
                        tab: {
                          name: "Instructions",
                        },
                        render: () => (
                          <div>
                            {success && (
                              <span className="text-sm mt-2">
                                {response?.data?.description}
                              </span>
                            )}
                          </div>
                        ),
                      },
                      {
                        tab: {
                          name: "Output",
                        },
                        render: () => (
                          <>
                            <OutputResult />
                          </>
                        ),
                      },
                    ]}
                  />
                </aside>
              </Fragment>
            )}
          </div>
        </MainLayout>

        <AnimatedModal
          isLoading={this.props.challenges.challenge.isLoading}
          title={
            this.props.challenges.challenge.response?.data.completed
              ? "This Challenge is Already Completed"
              : "You Completed this Challenge"
          }
          defaultOpen={false}
          open={
            this.props.challenges.challenge.response?.data.completed
              ? true
              : this.props.submission?.response?.data.success ?? false
          }
          actionBtn={goBack}
          actionBtnText={"Go Back"}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: IMapStateToProps) => ({
  challenges: state.challenges,
  submission: state.submission,
});

const mapDispatchToProps = {
  findOneById: ChallengeRepository.findOneById,
  create: SubmissionRepository.create,
  reset: SubmissionRepository.reset,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Test));
