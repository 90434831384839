import React, { Component } from "react";
import { Navigate, Outlet } from "react-router-dom";
import jwt_decode, { JwtPayload } from "jwt-decode";

interface IProtectedRouterProps {}

interface IProtectedState {
  isAuthenticated: boolean;
}

class ProtectedRouter extends Component<
  IProtectedRouterProps,
  IProtectedState
> {
  constructor(props: IProtectedRouterProps) {
    super(props);
    this.state = { isAuthenticated: this.isAuth() };
  }

  componentDidMount() {
    this.setState({ isAuthenticated: this.isAuth() });
  }

  isAuth(): boolean {
    let token = localStorage.getItem("access_token");

    if (token) {
      let tokenExpiration = jwt_decode<JwtPayload>(token).exp ?? 0;
      let dateNow = new Date();

      if (tokenExpiration < dateNow.getTime() / 1000) {
        localStorage.clear();
        // this.setState({
        //   isAuthenticated: false,
        // });
        return false;
      } else {
        // this.setState({
        //   isAuthenticated: true,
        // });
        return true;
      }
    } else if (token) {
      //   this.setState({
      //     isAuthenticated: true,
      //   });
      return true;
    } else {
      localStorage.clear();
      //   this.setState({
      //     isAuthenticated: false,
      //   });
      return false;
    }
  }

  render() {
    return !this.state.isAuthenticated ? (
      <Navigate to="/auth/login" replace />
    ) : (
      <Outlet />
    );
  }
}

export default ProtectedRouter;
