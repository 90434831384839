import { Component } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/Auth/Login";
import Register from "../pages/Auth/Register";
import Challenges from "../pages/Challenges";
import CreateChallenge from "../pages/Challenges/Create";
import Contests from "../pages/Contests";
import CreateContest from "../pages/Contests/Create";
import Home from "../pages/Home";
import Profile from "../pages/Profile";
import Test from "../pages/Test";
import ProtectedRouter from "./protected-router";

export default class CustomRouter extends Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<ProtectedRouter />}>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route
            path="contests/:id/challenge/:challengeId"
            element={<Test />}
          />
        </Route>
        <Route path="challenges" element={<ProtectedRouter />}>
          <Route path=":id" element={<Challenges />} />
          <Route path="create" element={<CreateChallenge />} />
        </Route>
        <Route path="contests" element={<ProtectedRouter />}>
          <Route path=":id" element={<Contests />} />
          <Route path="create" element={<CreateContest />} />
        </Route>
        <Route path="auth">
          <Route path="create" element={<Register />} />
          <Route path="login" element={<Login />} />
        </Route>
      </Routes>
    );
  }
}
