import moment from "moment";

export enum StatusOfContest {
  "Running",
  "Upcoming",
  "Expired",
}

export function checkStatusDate(
  start_at: string,
  end_at: string
): StatusOfContest {
  if (moment(Date.now()).isBetween(start_at, end_at))
    return StatusOfContest.Running;

  if (moment(Date.now()).isBefore(start_at)) return StatusOfContest.Upcoming;

  return StatusOfContest.Expired;
}
