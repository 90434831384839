import { Field } from "formik";
import React, { Component } from "react";
import classNames from "../../helpers/classNameMerger";

type options = {
  label: string;
  value: string;
};

export enum FlexType {
  Row = "flex flex-row items-center justify-center space-x-4",
  Column = "flex-col",
}

export type SelectFieldProps = {
  name: string;
  className?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  options: options[];
  onChange?: () => void;
  flexType?: FlexType;
  multiple?: boolean;
};

class SelectField extends Component<SelectFieldProps> {
  render() {
    return (
      <div className={this.props.flexType}>
        <label
          htmlFor={this.props.name}
          // className="sr-only"
          className={classNames(
            "text-sm text-gray-700 font-medium",
            this.props.flexType === FlexType.Column && "pb-2"
          )}
        >
          {this.props.label}
        </label>
        <Field
          as="select"
          id={this.props.name}
          name={this.props.name}
          className={classNames("input-select", this.props.className)}
          placeholder={this.props.placeholder}
          required={this.props.required}
          multiple={this.props.multiple}
        >
          {this.props.options.map((value, index) => (
            <option key={`option-${index}-${value.value}`} value={value.value}>
              {value.label}
            </option>
          ))}
        </Field>
      </div>
    );
  }
}

export default React.memo(SelectField);
