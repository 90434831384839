import { LockClosedIcon } from "@heroicons/react/solid";
import { Component } from "react";
import DefaultLoading from "../Loading/DefaultLoading";

type ButtonType = "button" | "submit" | "reset";

export type IconButtonType = {
  icon?: JSX.Element;
  label: string;
  type: ButtonType;
  isLoading?: boolean;
  disabled?: boolean;
};

export default class IconButton extends Component<IconButtonType> {
  render() {
    const { icon, label, type, isLoading, disabled } = this.props;
    return (
      <button type={type} className="btn" disabled={disabled}>
        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
          {isLoading && <DefaultLoading />}
          {icon
            ? icon
            : !isLoading && (
                <LockClosedIcon
                  className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                  aria-hidden="true"
                />
              )}
        </span>
        {label}
      </button>
    );
  }
}
