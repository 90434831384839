export class InitialValueHelpers {
  static getInitialValues = (values: Record<any, any>) => {
    let initialValues = {} as Record<any, any>;
    Object.keys(values).forEach((key) => {
      initialValues[key] = values[key].initialValue;
    });
    return initialValues;
  };

  static getSpecificValue = (values: Record<any, any>, keys: string[]) => {
    let initialValues = {} as Record<any, any>;
    keys.forEach((key) => {
      initialValues[key] = values[key];
    });
    return initialValues;
  };
}
