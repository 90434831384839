import { Component, Fragment } from "react";
import classNames from "../../helpers/classNameMerger";

export interface ITab {
  name: string;
  current?: boolean;
}

export interface ITabProps {
  className?: string;
  activeTab: number;
  activeTabState: any;
  tabs: ITab[];
}

export default class DefaultTab extends Component<ITabProps> {
  render() {
    const tabs = this.props.tabs;
    const changeTab = (index: number) => {
      this.props.activeTabState(index);
    };

    return (
      <Fragment>
        <div className={classNames("block", this.props.className)}>
          <nav className="flex space-x-4" aria-label="Tabs">
            {tabs.map((tab, index) => (
              <span
                key={tab.name}
                // href={tab.href}
                className={classNames(
                  tab.current
                    ? "bg-indigo-100 text-indigo-700"
                    : "text-gray-500 hover:text-gray-700",
                  "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                )}
                onClick={() => changeTab(index)}
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
              </span>
            ))}
          </nav>
        </div>
      </Fragment>
    );
  }
}
