import React, { Component } from "react";
import classNames from "../../helpers/classNameMerger";

export enum StatusBadgeType {
  Running = "bg-green-500",
  Upcoming = "bg-slate-100",
  Expired = "bg-red-500",
  Time = "bg-blue-500",
}

export interface IStatusBadgeProps {
  type: StatusBadgeType;
  label: string;
  icon: any;
  labelClass?: string;
}

class StatusBadge extends Component<IStatusBadgeProps> {
  render() {
    return (
      <span
        className={classNames(
          "p-1 text-gray-500 rounded-md px-2 text-sm flex space-x-1",
          this.props.type
        )}
      >
        {/* {this.props.type === StatusBadgeType.Upcoming ? (
          <this.props.icon className="w-4 text-gray-500" />
        ) : this.props.type === StatusBadgeType.Running ? (
          <this.props.icon className="w-4 text-white" />
        ) : (
          <this.props.icon className="w-4 text-white" />
        )} */}
        {
          <this.props.icon
            className={classNames(
              "w-4",
              this.props.labelClass ?? `text-gray-500`
            )}
          />
        }
        <span className={this.props.labelClass}>{this.props.label}</span>
      </span>
    );
  }
}

export default React.memo(StatusBadge);
