import { Form, Formik } from "formik";
import React, { Component, Fragment } from "react";
import Button from "../../../components/Button/Button";
import SelectField from "../../../components/Input/SelectField";
import TextField from "../../../components/Input/TextField";
import { RoundedTextFieldTypes } from "../../../interfaces/TextFieldProps";
import MainLayout from "../../../layout/MainLayout";
import SimpleMdeReact from "react-simplemde-editor";
import Editor from "@monaco-editor/react";
import { LanguageRepository } from "../../../respository/LanguageRepository";
import { ContestRepository } from "../../../respository/ContestRepository";
import { connect } from "react-redux";
import withRouter, { IRouterProps } from "../../../wrappers/withRouter";
import { ILanguageState } from "../../../store/slices/language-slice";
import { IContestState } from "../../../store/slices/contest-slice";
import getJwtValue from "../../../helpers/jwtDecode";
import { InitialValueHelpers } from "../../../helpers/getInitialValues";
import { ChallengeRepository } from "../../../respository/ChallengeRepository";
import { IChallengeState } from "../../../store/slices/challenge-slice";
import AnimatedModal from "../../../components/Modal/AnimatedModal";
import moment from "moment";
import { toast } from "react-toastify";

interface IMapStateToProps {
  contests: IContestState;
  languages: ILanguageState;
  challenges: IChallengeState;
  router: IRouterProps;
}

interface ICreateChallengeProps extends IMapStateToProps {
  findAllLanguage: () => void;
  findAllContests: (id: string) => void;
  create: (values: any) => void;
  reset: () => void;
}

interface ICreateChallengeState {
  description: string;
  default_code: string;
  test_case: string;
  success: boolean;
}

class CreateChallenge extends Component<
  ICreateChallengeProps,
  ICreateChallengeState
> {
  constructor(props: ICreateChallengeProps) {
    super(props);
    this.state = {
      description: "",
      default_code: "",
      test_case: "",
      success: false,
    };
  }

  componentDidMount() {
    if (this.props.challenges.success) {
      this.props.reset();
    }
    this.props.findAllLanguage();
    this.props.findAllContests(getJwtValue("org_id"));
    this.setState({
      ...this.state,
      success: true,
    });
  }

  render() {
    const handleSubmit = (values: any) => {
      let state = {
        ...values,
        ...this.state,
        language: values.language.map((val: string) => ({
          id: val,
        })),
        test_case: {
          test_code: this.state.test_case,
          submit_code: this.state.test_case,
        },
      };
      // console.log(state);
      this.props.create({
        id: state.contest,
        // id: state.contest.id,
        data: state,
      });
    };

    const getInitialValues = () => {
      const contests: any[] = this.props.contests.response?.data.filter(
        (data: any) => moment(data.end_at).isAfter()
      );
      !contests.length &&
        toast.info("There are not any active contests", { toastId: 1 });
      return {
        name: "",
        difficulty: "Easy",
        language: [
          InitialValueHelpers.getSpecificValue(
            this.props.languages.response?.data[0],
            ["id"]
          ),
        ],
        contest:
          contests.length > 0
            ? InitialValueHelpers.getSpecificValue(contests[0], ["id"])
            : null,
      };
    };

    const goBack = () => {
      this.props.reset();
      this.props.router.navigate("/");
    };

    const { isLoading } = this.props.challenges;

    return (
      <Fragment>
        <MainLayout title="Create Challenge">
          <div className="max-w-xl">
            {this.state.success &&
              this.props.contests.success &&
              this.props.languages.success && (
                <Formik
                  initialValues={getInitialValues()}
                  onSubmit={handleSubmit}
                >
                  <Form className="mt-8 space-y-6">
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-6">
                        <SelectField
                          // options={[
                          //   {
                          //     label: "Javascript (ES6)",
                          //     value: "js",
                          //   },
                          // ]}
                          options={this.props.contests.response.data
                            .filter((data: any) =>
                              moment(data.end_at).isAfter()
                            )
                            .map((data: any) => ({
                              label: data.name,
                              value: data.id,
                            }))}
                          name="contest"
                          label="Contest"
                          required={true}
                        />
                      </div>
                      <div className="sm:col-span-6">
                        <TextField
                          name="name"
                          label="Challenge Name"
                          showLabel={true}
                          required={true}
                          type="text"
                          rounded={RoundedTextFieldTypes.All}
                        />
                      </div>

                      <div className="sm:col-span-6">
                        <label
                          className={
                            "block text-sm font-medium text-gray-700 mb-2"
                          }
                        >
                          Description
                        </label>
                        {/* <TextField
                      name="name"
                      label="Description"
                      showLabel={true}
                      required={true}
                      type="text"
                      rounded={RoundedTextFieldTypes.All}
                    /> */}
                        <SimpleMdeReact
                          value={this.state.description}
                          onChange={(value) =>
                            this.setState({ description: value })
                          }
                        />
                      </div>

                      <div className="sm:col-span-6">
                        <SelectField
                          options={[
                            {
                              label: "Easy",
                              value: "Easy",
                            },
                            {
                              label: "Medium",
                              value: "Medium",
                            },
                            {
                              label: "Hard",
                              value: "Hard",
                            },
                          ]}
                          name="difficulty"
                          label="Difficulty"
                          required={true}
                        />
                      </div>

                      <div className="sm:col-span-6">
                        <SelectField
                          options={this.props.languages.response?.data.map(
                            (data: any) => ({
                              label: data.name,
                              value: data.id,
                            })
                          )}
                          name="language"
                          label="Language"
                          required={true}
                          multiple={true}
                        />
                      </div>

                      <div className="sm:col-span-6">
                        {/* <TextField
                      name="default_code"
                      label="Default Code"
                      showLabel={true}
                      required={true}
                      type="text"
                      rounded={RoundedTextFieldTypes.All}
                    /> */}
                        <label
                          className={
                            "block text-sm font-medium text-gray-700 mb-2"
                          }
                        >
                          Default Code
                        </label>
                        <Editor
                          className="lg:max-h-[20rem] mt-4"
                          defaultLanguage="javascript"
                          options={{
                            minimap: {
                              enabled: false,
                            },
                          }}
                          onChange={(value) =>
                            this.setState({ default_code: `${value}` })
                          }
                        />
                      </div>

                      <div className="sm:col-span-6">
                        {/* <TextField
                      name="test_code"
                      label="Test Code"
                      showLabel={true}
                      required={true}
                      type="text"
                      rounded={RoundedTextFieldTypes.All}
                    /> */}
                        <label
                          className={
                            "block text-sm font-medium text-gray-700 mb-2"
                          }
                        >
                          Test Code
                        </label>
                        <Editor
                          className="lg:max-h-[20rem] mt-4"
                          defaultLanguage="javascript"
                          options={{
                            minimap: {
                              enabled: false,
                            },
                          }}
                          defaultValue={`it('What to Test Title', () => {
    expect(functionName("inutValue")).toBe("expectedResult"); 
});    `}
                          onChange={(value) =>
                            this.setState({ test_case: `${value}` })
                          }
                        />
                      </div>

                      <div className="sm:col-span-6 mt-4">
                        <Button
                          type="submit"
                          label="Submit"
                          isLoading={isLoading}
                          disabled={isLoading}
                        />
                      </div>
                    </div>
                  </Form>
                </Formik>
              )}
          </div>
        </MainLayout>
        {this.state.success && (
          <AnimatedModal
            isLoading={this.props.challenges.isLoading}
            title={"Successfully added Challenge"}
            defaultOpen={false}
            open={this.props.challenges.success ?? false}
            actionBtn={goBack}
            actionBtnText={"Go Home"}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: ICreateChallengeProps) => {
  return {
    contests: state.contests,
    languages: state.languages,
    challenges: state.challenges,
  };
};

const mapDispatchToProps = {
  findAllLanguage: LanguageRepository.findAll,
  findAllContests: ContestRepository.findAll,
  create: ChallengeRepository.create,
  reset: ChallengeRepository.reset,
};

export default React.memo(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateChallenge))
);
