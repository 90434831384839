import * as Yup from "yup";

export class ProfileSchema {
  static readonly profileUpdateSchema = Yup.object().shape({
    avatar: Yup.mixed(),
    number: Yup.string()
      .required("Number is required")
      .trim()
      .min(10, "Minimum 10 Characters Required")
      .max(10, "Maximum 10 Characters is Valid"),
    bio: Yup.string()
      .required("Bio is required")
      .trim()
      .min(3, "Minimum 3 Characters Required")
      .max(50, "Maximum 50 Characters is Valid"),
  });
}
