import React, { Component } from "react";

type CompanyCardProps = {
  name: string;
  src: string;
};

class CompanyCard extends Component<CompanyCardProps> {
  render() {
    return (
      <div className="rounded-md shadow bg-white p-2 cursor-pointer transition-shadow hover:shadow-md flex items-center justify-between flex-col">
        <img
          className="max-w-xs max-h-20 rounded-full"
          alt={this.props.name}
          src={this.props.src}
        />
        <span className="p-1 pt-3">{this.props.name}</span>
      </div>
    );
  }
}

export default React.memo(CompanyCard);
