import React, { Component } from "react";

export type AuthLayoutProps = {
  children: React.ReactNode;
  title: string;
};

export default class AuthLayout extends Component<AuthLayoutProps> {
  render() {
    return (
      <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img
              className="mx-auto h-28 w-auto"
              src={require("../assets/images/logo.png")}
              alt="logo"
            />
            <h2 className="text-center text-3xl font-extrabold text-gray-900">
              {this.props.title}
            </h2>
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }
}
