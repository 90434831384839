import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import parseErrorMessage from "../../helpers/parseErrorMessage";
import { ContestRepository } from "../../respository/ContestRepository";

export interface IContestState {
  isLoading: boolean;
  error?: Object;
  success?: boolean;
  response?: any;
}

const initialState: IContestState = {
  isLoading: false,
};

const contestSlice = createSlice({
  name: "contests",
  initialState,
  reducers: {
    findAll(state) {
      state.isLoading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ContestRepository.findAll.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(ContestRepository.findAll.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.response = action.payload;
      })
      .addCase(ContestRepository.findAll.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error;
        toast.error(parseErrorMessage(error.message));
      })
      .addCase(ContestRepository.create.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(ContestRepository.create.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        toast.success("Successfully created Contest");
      })
      .addCase(ContestRepository.create.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error;
        toast.error(parseErrorMessage(error.message));
      });
  },
});

export const { findAll } = contestSlice.actions;

export default contestSlice.reducer;
