import moment from "moment";
import * as Yup from "yup";

export class ContestSchema {
  static readonly contestCreateSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .trim()
      .min(3, "Minimum 3 Characters Required")
      .max(50, "Maximum 50 Characters is Valid"),
    start_at: Yup.date()
      .required("Start Date is required")
      .min(
        moment().subtract(1, "day"),
        "Minimum date should be today or greater than today"
      )
      .test(
        "is-start-at-valid",
        "Start At must be less than contest end date",
        function (start_at: Date | undefined): boolean {
          return this.parent.end_at
            ? moment(start_at).isBefore(this.parent.end_at)
            : true;
        }
      ),
    end_at: Yup.date()
      .required("End Date is required")
      .min(
        moment().subtract(1, "day"),
        "Minimum date should be today or greater than today"
      )
      .test(
        "is-end-at-valid",
        "End At must be greater than contest start date",
        function (end_at: Date | undefined): boolean {
          return this.parent.start_at
            ? moment(end_at).isAfter(this.parent.start_at)
            : true;
        }
      ),
  });
}
