export type TChallengeValues = {
  id: string;
  challengeId: string;
};

export type ChallengeFilterType = {
  search: string;
  created_at: string;
  language: string;
};

export type ChallengeFilterPrams = { id: string; params: Object };

export const initialValueFilterChallenges: ChallengeFilterType = {
  search: "",
  created_at: "ASC",
  language: "js",
};
