import CustomRouter from "./routes/router";
import "./assets/css/build.css";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import CustomToastContainer from "./components/Toast/ToastContainer";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <CustomRouter />
      </div>
      <CustomToastContainer />
    </BrowserRouter>
  );
}

export default App;
