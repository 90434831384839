import { Disclosure } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { Component } from "react";
import { Fade } from "react-awesome-reveal";
import { connect } from "react-redux";
import classNames from "../../helpers/classNameMerger";
import { ISubmissionState } from "../../store/slices/submission-slice";
import withRouter, { IRouterProps } from "../../wrappers/withRouter";
import LoadingIndicator from "../Indicator/Loading";

interface IMapStateToProps {
  submission: ISubmissionState;
  router: IRouterProps;
}

interface IOutputResultProps extends IMapStateToProps {}

interface IOutputResultState {}

class OutputResult extends Component<IOutputResultProps, IOutputResultState> {
  render() {
    return (
      <div className="px-2">
        <div className="space-x-2 text-sm flex items-center">
          {/* <span className="">Passed: 0</span>
          <span className="text-red-800">Failed: 0</span> */}
          {this.props.submission.isLoading && <LoadingIndicator />}
          {this.props.submission.success && (
            <span
              className={classNames(
                this.props.submission.response?.data.success
                  ? "text-green-500"
                  : "text-red-800"
              )}
            >
              Status:{" "}
              {this.props.submission.response?.data.success
                ? "Success"
                : "Failed"}
            </span>
          )}
        </div>
        <div className="mt-4">
          {/* <div className="text-sm mb-3">Test Results:</div> */}
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <>
                <Disclosure.Button>
                  <div className="flex">
                    <ChevronRightIcon
                      className={`${
                        open ? "transform rotate-90" : ""
                      } w-5 transition-transform`}
                    />
                    <span className="text-sm">Test Result</span>
                  </div>
                </Disclosure.Button>
                <Disclosure.Panel>
                  {this.props.submission.success && (
                    <div className="mt-2">
                      {/* <div
                        className={classNames(
                          "text-xs pl-4 pr-2 pt-2 font-poppins",
                          this.props.submission.response?.data.success
                            ? "text-green-500"
                            : "text-red-700"
                        )}
                        dangerouslySetInnerHTML={{
                          __html:
                            this.props.submission.response?.data.logs.testResults[0].message
                              .split("|")[0]
                              .replaceAll("\n", "<br />")
                              .replaceAll("●", ""),
                        }}
                      /> */}
                      <div>
                        {this.props.submission.response?.data ? (
                          <div className="space-y-2">
                            {this.props.submission.response?.data.logs.testResults[0].assertionResults.map(
                              (result: any, index: number) => (
                                <Fade
                                  key={`result-check-${index}`}
                                  cascade
                                  triggerOnce
                                  damping={0.2}
                                >
                                  <div
                                    className={classNames(
                                      result.status === "failed"
                                        ? "border-red-500"
                                        : "border-green-500",
                                      "flex border-l-4 py-1 pl-2"
                                    )}
                                  >
                                    <span className="text-xs">
                                      {result.fullName}
                                    </span>
                                  </div>
                                </Fade>
                              )
                            )}
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  )}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IMapStateToProps) => ({
  submission: state.submission,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OutputResult)
);
