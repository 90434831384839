import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import parseErrorMessage from "../../helpers/parseErrorMessage";
import { OrganizationRepository } from "../../respository/OrganizationRepository";

export interface IOrganizationState {
  isLoading: boolean;
  error?: Object;
  success?: boolean;
  response?: any;
}

const initialState: IOrganizationState = {
  isLoading: false,
};

const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    findAll(state) {
      state.isLoading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(OrganizationRepository.findAll.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(OrganizationRepository.findAll.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.response = action.payload;
      })
      .addCase(OrganizationRepository.findAll.rejected, (state, { error }) => {
        state.isLoading = false;
        state.error = error;
        toast.error(parseErrorMessage(error.message));
      });
  },
});

export const { findAll } = organizationSlice.actions;

export default organizationSlice.reducer;
