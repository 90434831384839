import React, { Component } from "react";
import { ToastContainer, Zoom } from "react-toastify";

class CustomToastContainer extends Component {
  render() {
    return (
      <ToastContainer
        transition={Zoom}
        position="bottom-right"
        autoClose={4000}
        closeButton={false}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    );
  }
}

export default React.memo(CustomToastContainer);
