import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../builders/axiosInstance";
import { TSubmissionValues } from "../interfaces/SubmissionInterfaces";
import { reset } from "../store/slices/submission-slice";

export class SubmissionRepository {
  static create = createAsyncThunk(
    "submission/create",
    async (values: TSubmissionValues) => {
      try {
        return (
          await axiosInstance.post(
            `/challenges/${values.id}/submissions`,
            values.data
          )
        ).data;
      } catch (error: any) {
        throw new Error(JSON.stringify(error.response.data));
      }
    }
  );

  static reset = createAsyncThunk("submission/reset", async (_, __) => {
    __.dispatch(reset());
  });
}
