import { LoginValues, RegisterValues } from "../interfaces/AuthInterfaces";
import { ContestValues } from "../interfaces/ContestInterfaces";

export const loginInitialValues: LoginValues = {
  email: "",
  password: "",
};

export const createInitialValues: RegisterValues = {
  username: "",
  email: "",
  password: "",
};

export const contestInitialValues: ContestValues = {
  name: "",
  start_at: "",
  end_at: "",
};
